.loading {
    top: 50%;
    width: 100%;
}

$primary-color: #488bb8;
.full-width-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 150px);
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $primary-color;
    border-color: $primary-color transparent $primary-color transparent;
    animation: lds-dual-ring 0.5s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// svg
circle {
    transform-origin: center;
    transform-box: fill-box;
    transform-origin: center;
    animation: rotate linear infinite;
}
circle:nth-child(1) {
    animation-duration: 0.8s;
}
circle:nth-child(2) {
    animation-duration: 0.6s;
}
circle:nth-child(3) {
    animation-duration: 0.4s;
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
svg {
    width: 100px;
    height: 100px;
}
