.main {
    background-color: #fff;
    min-height: 100%;
    padding: 40px;
}
.contain {
    padding: 40px;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

@media (max-width: 480px) {
    .main {
        background-color: azure;
        padding: 5px;
    }
    .contain {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .main {
        background-color: azure;
        padding: 5px;
    }
    .contain {
        padding: 5px;
    }
}

// faq
.faq-accordion {
    color: #000 !important;
}
